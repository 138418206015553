import Tracker from '@openreplay/tracker';
import Cookies from 'js-cookie';

var initOpts = {
  projectKey: window.theme.orpProjectKey,
  ingestPoint: window.theme.orpIngestPoint,
  //defaultInputMode: 0,
  //obscureTextNumbers: false,
  //obscureTextEmails: true,
  onStart: ({ sessionToken }) => {
    Cookies.set('openReplaySessionToken', sessionToken, { path: '/' });
    if (typeof window.Sentry !== 'undefined') {
      window.Sentry.setTag('openReplaySessionToken', sessionToken);
    }
  },
};
console.log('init ORP');
window.OrpTracker = new Tracker(initOpts);

var startOpts = {
  metadata: {
    ip: window.theme.clientIp,
    domain: window.location.hostname.replace(/^www\.(.*\.com)$/, '$1'),
  },
};
window.OrpTracker.start(startOpts);

document.addEventListener('keydown', e => {
  if (e.key === 'Tab' || e.keyCode === 9) {
    window.OrpTracker.event('keyboard_nav', {
      key: 'Tab',
      focusedElement: document.activeElement?.tagName,
    });
    // You can add additional data if needed, e.g., which element was focused.
  }
});

window.setSentryOrpSessionUrl = function () {
  try {
    if (typeof window.Sentry !== 'undefined') {
      window.Sentry.setTag(
        'openReplaySessionURL',
        window.OrpTracker.getSessionURL(),
      );
    }
  } catch (e) {
    window.captureError(
      "cannot set Sentry tag 'openReplaySessionToken' with message " +
        e.message,
    );
  }
};

window.addEventListener('load', function () {
  window.setSentryOrpSessionUrl();
});
